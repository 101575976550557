import path from "path";

export default {
  API_HOST: "https://api1.sonikpocket.com/api/",
  HOST: "https://api1.sonikpocket.com/",
  HOMEPAGE: "https://sonikpocket.com/",
  HOMEPAGE_MOBILE: "https://sonikpocket.com/m/child/login",
  GAMES_URL: "https://games.sonikpocket.com/",
  BASE_DIR: path.join(__dirname, "../"),
  DELIVERY_COST: 1,
  VAT: 0.19,
};

// export default {
//   API_HOST: "https://api1.dev.sonikpocket.com/api/",
//   HOST: "https://api1.dev.sonikpocket.com/",
//   HOMEPAGE: "https://dev.sonikpocket.com/",
//   HOMEPAGE_LOCAL: "http://192.168.100.14:5000/m/child/login",
//   // HOMEPAGE_MOBILE: "https://dev.sonikpocket.com/m/child/login",
//   HOMEPAGE_MOBILE: "https://dev.sonikpocket.com/",
//   GAMES_URL: "https://games.dev.sonikpocket.com/",
//   BASE_DIR: path.join(__dirname, "../"),
//   DELIVERY_COST: 1,
//   VAT: 0.19,
// };
