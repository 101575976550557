<template>
  <div style="padding: 0 20px">
    <div v-if="selectedSection == 'chooseLocation'" class="appCheckout">
      <div style="margin: 20px 0; text-align: center; font-weight: 600">
        Choose your delivery address
      </div>
      <!-- <div class="appCheckout__container">
        <img
          :src="resolveImage(checkout.defaultPicture)"
          class="appCheckout__container__image"
        />
        <div class="appCheckout__container__details">
          <div class="appCheckout__container__details__title">
            {{ checkout.name }}
          </div>

          <div class="appCheckout__container__details__price">
            £{{ formatPrice(checkout.productPrice) }}
          </div>
        </div>
      </div>
      <div style="display: none">
        {{ selectedAddressId }}
      </div>
      <div class="appCheckout__center">
        <img
          src="../../assets/images/arrow_down.png"
          class="appCheckout__center__arrow"
        />
      </div> -->

      <div v-if="getDeliveryAddresses.length > 0">
        <div
          v-for="address in default_address.filter(
            (item) => item.is_default == true
          )"
          :key="address.id"
          class="shadow"
          style="border: none; border-radius: 15px"
          :class="{
            'active-address': activeAddressIndex === address.id,
          }"
          @click="selectFirstAddress(address.id)"
        >
          <!-- <div v-if="address.type == 'Home'">
            <img
              src="@/assets/images/address_home.png/"
              class="appProfileAdress__house"
              :alt="address.type"
            />
          </div>
          <div v-else-if="address.type == 'Work'">
            <img
              src="@/assets/images/address_work.png/"
              class="appProfileAdress__house"
              :alt="address.type"
            />
          </div>
          <div v-else-if="address.type == 'Grandma'">
            <img
              src="@/assets/images/address_grandma.png/"
              class="appProfileAdress__house"
              :alt="address.type"
            />
          </div>
          <div v-else-if="address.type == 'Grandpa'">
            <img
              src="@/assets/images/address_grandpa.png/"
              class="appProfileAdress__house"
              :alt="address.type"
            />
          </div>
          <div v-else-if="address.type == 'Other'">
            <img
              src="@/assets/images/address_other.png/"
              class="appProfileAdress__house"
              :alt="address.type"
            />
          </div> -->

          <div class="appCheckout__address__content">
            <div class="appCheckout__address__details" style="padding: 20px">
              <div
                class="appCheckout__address__details__name font-bold"
                style="font-size: 14px"
              >
                {{ address.type }}
                <span v-if="address.is_default" class="isDefault">Default</span>
              </div>
              <div
                class="appCheckout__address__details__description"
                style="font-size: 12px"
              >
                {{ address.address }}
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="addresses.length > 0"
          class="notThisAddress"
          @click="showAllAddresses"
        >
          Not this address
        </div>

        <div v-if="hiddenAddresses">
          <div
            v-for="address in addresses.filter(
              (item) => item.is_default == false
            )"
            :key="address.id"
            class="appCheckout__address shadow"
            :class="{
              'active-address': activeAddressIndex === address.id,
            }"
            style="border: none; border-radius: 15px"
            @click="selectAddress(address.id)"
          >
            <!-- <div v-if="address.type == 'Home'">
              <img
                src="@/assets/images/address_home.png/"
                class="appProfileAdress__house"
                :alt="address.type"
              />
            </div>
            <div v-else-if="address.type == 'Work'">
              <img
                src="@/assets/images/address_work.png/"
                class="appProfileAdress__house"
                :alt="address.type"
              />
            </div>
            <div v-else-if="address.type == 'Grandma'">
              <img
                src="@/assets/images/address_grandma.png/"
                class="appProfileAdress__house"
                :alt="address.type"
              />
            </div>
            <div v-else-if="address.type == 'Grandpa'">
              <img
                src="@/assets/images/address_grandpa.png/"
                class="appProfileAdress__house"
                :alt="address.type"
              />
            </div>
            <div v-else-if="address.type == 'Other'">
              <img
                src="@/assets/images/address_other.png/"
                class="appProfileAdress__house"
                :alt="address.type"
              />
            </div> -->

            <div class="appCheckout__address__content">
              <div class="appCheckout__address__details" style="padding: 20px">
                <div
                  class="appCheckout__address__details__name font-bold"
                  style="font-size: 14px"
                >
                  {{ address.type }}
                  <span v-if="address.is_default" class="isDefault"
                    >Default</span
                  >
                </div>
                <div
                  class="appCheckout__address__details__description"
                  style="font-size: 12px"
                >
                  {{ address.address }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="notifyGuardian" @click="notifyGuardianAction()">
          <img src="../../assets/images/bell.png" alt="Bell" width="15" />
          Tap to notify your guardian to add an address
        </div>
      </div>

      <AppErrorMessage :message="error" />

      <AppButton
        :disabled="selectedAddressId == null || isOrderLoading"
        :text="isOrderLoading ? 'Processing...' : 'Send order'"
        :color="buttonColor"
        class="rounded-[100px] py-3 px-12 text-white text-sm font-bold"
        :mini="true"
        :isLoading="isOrderLoading"
        style="
          width: 90%;
          position: fixed;
          bottom: 70px;
          left: 0;
          right: 0;
          margin: 0 auto;
        "
        @click="sendOrder"
      />
    </div>
    <div
      v-if="selectedSection == 'yourReceipt'"
      id="yourReceipt"
      class="appCheckout"
    >
      <div class="appCheckout__receipt">
        <div class="appCheckout__receipt__top">
          <div class="appCheckout__container__details__title">Your receipt</div>
        </div>
        <div class="appCheckout__receipt__content">
          <div class="appCheckout__receipt__content__item">
            <div class="appCheckout__receipt__content__item__name">
              {{ checkout.name }}
            </div>
            <div class="appCheckout__receipt__content__item__price">
              £{{ formatPrice(getLastOrder.price_without_tva) }}
            </div>
          </div>
          <div class="appCheckout__receipt__content__item">
            <div class="appCheckout__receipt__content__item__name">
              Delivery Cost
            </div>
            <div class="appCheckout__receipt__content__item__price">
              £{{ formatPrice(getLastOrder.cart.delivery_cost) }}
            </div>
          </div>
          <div class="appCheckout__receipt__content__item">
            <div class="appCheckout__receipt__content__item__name">V.A.T</div>
            <div class="appCheckout__receipt__content__item__price">
              £{{ formatPrice(getLastOrder.tva_value) }}
            </div>
          </div>
          <div class="appCheckout__receipt__content__line" />
          <div
            class="appCheckout__receipt__content__item"
            style="font-weight: 600"
          >
            <div
              class="appCheckout__receipt__content__item__price"
              style="color: #36d5f2"
            >
              Total
            </div>
            <div
              class="appCheckout__receipt__content__item__price"
              style="color: #36d5f2"
            >
              £{{
                formatPrice(
                  getLastOrder.product_net_price +
                    Number(getLastOrder.cart.delivery_cost)
                )
              }}
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="appCheckout__game">
        <div class="appCheckout__game__title">
          How much will be remaining?
        </div>
        <div class="appCheckout__game__wallets">
          <AppDashboardWallet
            v-if="fromGoalOrStore == 'goal'"
            wallet="Spend"
            :balance="remainingMoney"
            class="appCheckout__game__wallets__item"
          />
          <AppDashboardWallet
            v-else
            wallet="Spend"
            :balance="getSpendWalletBalance"
            class="appCheckout__game__wallets__item"
          />
          <AppCostWallet
            :balance="productPrice"
            class="appCheckout__game__wallets__item"
          />
        </div>
        <AppInput
          type="number"
          placeholder="Your answer is: "
          class="appCheckout__game__input"
          v-model="gameResponse"
          @input="validateButton"
        />
        <div class="appCheckout__game__actions">
          <AppButton
            text="Answer"
            :color="answerColor"
            :mini="true"
            class="appCheckout__game__actions__button"
            @click="verifyAnswer"
          >
          </AppButton>
        </div>
       
      </div> -->
      <div class="thankYouContainer">
        <AppButton
          text="Thank You!"
          color="red"
          :mini="true"
          class="bg-[#58C1E1] rounded-[100px] py-3 px-12 text-white text-sm font-bold"
          style="
            width: 90%;
            position: fixed;
            bottom: 70px;
            left: 0;
            right: 0;
            margin: 0 auto;
          "
          @click="thankYou"
        />
      </div>
    </div>

    <!-- <div v-if="selectedSection == 'bonusPoints'" class="bonusPoints__correct">
      <div
        v-if="this.currentPointsNumber > 0"
        class="bonusPoints__correct__text"
      >
        Correct!
      </div>
      <div
        v-if="this.currentPointsNumber > 0"
        class="bonusPoints__correct__text"
      >
        +{{ currentPointsNumber }} Sonik Points
      </div>

      <div
        v-if="this.currentPointsNumber == 0"
        class="bonusPoints__correct__text"
      >
        Incorrect!
      </div>
      <div
        v-if="this.currentPointsNumber == 0"
        class="bonusPoints__correct__text"
      >
        No Sonik Points
      </div>
      <AppButton
        text="Close"
        color="blue"
        :mini="true"
        class="bonusPoints__button"
        @click="closeModal"
      >
      </AppButton>
    </div> -->
  </div>
</template>

<script>
  import AppButton from "../../components/AppButton";
  import AppErrorMessage from "../../components/AppErrorMessage";
  // import AppDashboardWallet from "@/components/AppDashboardWallet";
  // import AppCostWallet from "@/components/AppCostWallet";
  // import AppInput from "@/components/AppInput";

  import { mapGetters, mapActions } from "vuex";
  import axios from "axios";
  import config from "../../config";
  import status from "../../utils/statusCode";

  export default {
    components: {
      AppButton,
      AppErrorMessage,
      // AppDashboardWallet,
      // AppCostWallet,
      // AppInput,
    },
    props: {
      name: {
        type: String,
        default: "",
      },
      description: {
        type: String,
        default: "",
      },
      defaultPicture: {
        type: String,
        default: "",
      },
      productId: {
        type: Number,
        default: 0,
      },
      goalId: {
        type: Number,
        default: 0,
      },
      productPrice: {
        // type: Number,
        // default: 0
      },
      fromGoalOrStore: {
        type: String,
        default: "",
      },
    },
    data() {
      return {
        activeAddressIndex: null,
        currentPointsNumber: 0,
        selectedSection: "chooseLocation",
        addresses: [],
        default_address: [],
        selectedAddressId: null,
        selected: [],
        error: "",
        buttonColor: null,
        config: [],
        gameResponse: null,
        answerColor: null,
        pointsNumber: 0,
        hiddenAddresses: false,
        isOrderLoading: false,
      };
    },
    computed: {
      ...mapGetters([
        "getSpendWalletBalance",
        "getSpendWalletAndGoalsBalance",
        "getDeliveryAddresses",
        "getLastOrder",
      ]),
      remainingMoney() {
        return Number(this.getSpendWalletBalance).toFixed(2);
      },
      checkout() {
        return JSON.parse(localStorage.getItem("checkout"));
      },
    },
    created() {
      const user = {
        userId: localStorage.getItem("children_user_id"),
        token: localStorage.getItem("children_token"),
        family_id_check: localStorage.getItem("children_family_id"),
      };
      this.fetchProfile();
      this.setupUser(user);
      this.config = config;
      setTimeout(() => {
        this.default_address = this.getDeliveryAddresses.filter(
          (item) => item.is_default == true
        );
        this.addresses = this.getDeliveryAddresses.filter(
          (item) => item.is_default == false
        );
      }, 1000);
    },
    methods: {
      ...mapActions([
        "sendOrder",
        "fetchUser",
        "addPoints",
        "notifyGuardianAction",
        "fetchProfile",
        "setupUser",
        "TOAST"
      ]),
      resolveImage(src) {
        return config.HOST + src;
      },
      showAllAddresses() {
        this.hiddenAddresses = !this.hiddenAddresses;
      },
      selectAddress(addressId) {
        this.selectedAddressId = addressId;
        this.buttonColor = "blue";

        if (this.activeAddressIndex === addressId) {
          this.activeAddressIndex = null;
        } else {
          this.activeAddressIndex = addressId;
        }
      },
      selectFirstAddress(addressId) {
        this.selectedAddressId = addressId;
        this.buttonColor = "blue";

        if (this.activeAddressIndex === addressId) {
          this.activeAddressIndex = null;
        } else {
          this.activeAddressIndex = addressId;
        }
      },
      thankYou() {
        this.$router.replace("/children");
      },
      sendOrder() {
        const token = localStorage.getItem("children_token");
        const userId = localStorage.getItem("children_user_id");
        const familyIdChk = localStorage.getItem("children_family_id");

        this.isOrderLoading = true;
        
        let details = {
            delivery_address_id: this.selectedAddressId,
            status: "paid",
            product_id: this.checkout.productId,
            goal_id: this.checkout.goalId,
            quantity: 1,
            by_from: this.checkout.fromGoalOrStore,
        };

        axios
          .post("/order/child/add", {
            userId: userId,
            token: token,
            family_id_check: familyIdChk,
            delivery_address_id: details.delivery_address_id,
            status: details.status,
            product_id: details.product_id,
            quantity: details.quantity,
            by_from: details.by_from,
            goal_id: details.goal_id,
          })
          .then((response) => {
           
            const { statusCode } = response.data;

            if (status.success(statusCode)) {
              this.$store.commit("SEND_ORDER", response.data.data);

            //   if (this.$store.state.currentOrderSonikPoints > 0) {
                this.$store.state.lastOrderId = response.data.data.id;

                let payload = {
                  points: this.$store.state.currentOrderSonikPoints,
                  order_id: response.data.data.id,
                  game_nr: 1,
                };

                this.$store.dispatch("addPoints", payload);
                this.$store.dispatch("fetchStore");
                this.$store.dispatch("fetchUser");

                this.$store.state.currentOrderSonikPoints = 0;
                this.isOrderLoading = false;
                this.error = "";
                this.selectedSection = "yourReceipt";
            //   }
            } else if (status.invalidToken(statusCode)) {
                this.isOrderLoading = false;
                deleteStorage();
            } else if (status.error(statusCode)) {
                this.isOrderLoading = false;
                this.TOAST(
                "Something went wrong! Please contact our support team."
              );
            }
          })
          .catch((error) => {
            this.isOrderLoading = false;
            this.TOAST(
              "Something went wrong! Please contact our support team."
            );
            throw error;
          });
      },
      addOrder() {
        this.isOrderLoading = true;

        if (this.selectedAddressId == null) {
          this.error = "Invalid address!";
        } else {
          var details = {
            delivery_address_id: this.selectedAddressId,
            status: "paid",
            product_id: this.checkout.productId,
            goal_id: this.checkout.goalId,
            quantity: 1,
            by_from: this.checkout.fromGoalOrStore,
          };

          this.sendOrder(details);
          this.error = "";
          this.selectedSection = "yourReceipt";
          this.$store.state.loading = false;
          this.isOrderLoading = false;
        }
      },
      validateButton() {
        if (this.gameResponse.length > 0) {
          this.answerColor = "blue";
        } else {
          this.answerColor = null;
        }
      },
      verifyAnswer() {
        if (this.gameResponse.length > 0) {
          // console.log("raspunsul meu");
          // console.log(this.gameResponse);
          // console.log("raspuns corect");
          // console.log(
          //   Math.max(this.getSpendWalletBalance, this.checkout.productPrice) -
          //   Math.min(this.getSpendWalletBalance, this.checkout.productPrice)
          // );
          if (
            this.gameResponse ==
            Math.max(this.getSpendWalletBalance, this.checkout.productPrice) -
              Math.min(this.getSpendWalletBalance, this.checkout.productPrice)
          ) {
            this.selectedSection = "bonusPoints";
            this.currentPointsNumber = (this.checkout.productPrice / 2).toFixed(
              2
            );
            // console.log(this.currentPointsNumber);
            // let payload = {
            //   points: this.currentPointsNumber,
            //   order_id: this.$store.state.lastOrderId,
            //   game_nr: 2,
            // };
            // this.addPoints(payload);
          } else {
            this.selectedSection = "bonusPoints";
            this.currentPointsNumber = 0;
          }
        } else {
          this.answerColor = null;
          this.gameResponse = "";
        }

        // console.log(this.gameResponse);

        this.fetchUser();
      },
      closeModal() {
        if (this.checkout.fromGoalOrStore == "goal") {
          location.reload();
        }
        this.$emit("closeModal", true);

        this.fetchUser();
      },
    },
  };
</script>

<style lang="scss">
  .address_active {
    border: 3px solid #36d5f2;
  }
  .appCheckout {
    width: auto;

    &__game {
      width: 100%;

      &__title {
        display: flex;
        align-items: center;
        justify-content: center;

        font-size: 40px;
        line-height: 40px;
        color: #7a615a;
        margin-top: 50px;
        width: 100%;
      }

      &__wallets {
        margin-top: 30px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 30px;

        &__item {
          height: 100%;
          width: 100%;
        }
      }

      &__input {
        width: 100% !important;
      }

      &__actions {
        display: flex;
        align-items: center;
        justify-content: center;

        &__button {
          margin-top: 40px;
          width: 50%;
        }
      }
    }

    &__header {
      display: flex;
      align-items: center;
      justify-content: center;

      font-size: 20px;
      line-height: 21px;
      color: #ff4f79;
      margin: auto;
      margin-bottom: 40px;
    }

    &__container {
      display: grid;
      grid-template-columns: 1fr 1fr;

      &__image {
        width: 100%;
      }

      &__details {
        width: 100%;

        &__title {
          font-size: 30px;
          line-height: 21px;
          color: #7a615a;
          padding: 10px;
          margin-left: 20px;
        }
        &__description {
          font-size: 25px;
          line-height: 21px;
          color: #7a615a;
          padding: 10px;
          margin-left: 20px;
        }

        &__price {
          font-size: 25px;
          line-height: 25px;
          color: #36d5f2;
          padding: 10px;
          margin-left: 20px;
        }
      }
    }

    &__center {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: 30px;
      margin-bottom: 20px;

      &__arrow {
        width: 30px;
      }
    }

    &__address {
      width: 100%;
      min-height: 100px;
      max-height: 300px;
      // border: 2px dashed #e5e5e5;
      box-sizing: border-box;
      border-radius: 10px !important;
      display: flex;
      // grid-template-columns: 1fr 9fr;
      align-items: center;
      margin-bottom: 20px;

      &__image {
        padding: 20px;
        width: 100px;
        height: 100px;
      }

      &__details {
        width: 100%;
        padding: 20px;

        &__name {
          font-size: 30px;
          line-height: 25px;
          color: #7a615a;
          display: flex;
          align-items: center;
        }

        &__description {
          font-size: 20px;
          line-height: 21px;
          color: #7a615a;
          margin-top: 10px;
          margin-right: 20px;
        }
      }
    }

    // &__button {
    //   margin-left: 30px;
    //   margin-right: 30px;
    // }

    &__receipt {
      width: auto;
      padding: 10px;
      background: #ffffff;
      // border-radius: 20px;

      &__top {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        padding: 20px;
        background: #ffffff;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
        border-bottom: dotted #e5e5e5;
      }

      &__content {
        border-radius: 20px;
        padding: 20px;
        background: #ffffff;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);

        &__item {
          width: 100%;
          padding: 5px;
          display: grid;
          grid-template-columns: 10fr 3fr;

          &__name {
            font-size: 16px;
            line-height: 21px;
            color: #7a615a;
          }

          &__price {
            font-size: 16px;
            line-height: 25px;
            color: #7a615a;
            margin-right: 20px;
          }
        }

        &__line {
          width: 100%;
          height: 1px;
          background-color: #e5e5e5;
          margin: 5px 0;
        }
      }
    }
  }
  @media screen and (max-width: 850px) {
    .appCheckout__address {
      margin-left: -35px;
      margin-right: -30px;
      width: auto;
    }
    .appCheckout__address__image {
      height: 100px;
      width: 100px;
      margin-top: 10px;
    }
    .appCheckout__address__details {
      padding: 10px;
      margin-top: 10px;
      margin-bottom: 10px;
      margin-right: 10px;
    }
    .appCheckout__game__wallets {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 30px;
    }
    .appCheckout__game__actions__button {
      width: 100%;
    }
  }

  @media screen and (max-width: 850px) {
    .appCheckout__address {
      border-radius: 20px;
      margin: 10px 0;
    }
    .appCheckout__address__details__name {
      font-size: 18px;
    }
    .appCheckout__address__details__description {
      font-size: 16px;
      line-height: 16px;
      margin-top: 5px;
    }
    .active-address {
      background: #36d5f2;
      border-color: #36d5f2;
      .appCheckout__address__details__name,
      .appCheckout__address__details__description {
        color: white;
      }
    }
  }

  .appCheckout__address {
    border-radius: 20px;
    margin: 10px 0;
  }
  .appCheckout__address__details__name {
    font-size: 18px;
  }
  .appCheckout__address__details__description {
    font-size: 16px;
    line-height: 16px;
    margin-top: 5px;
  }
  .active-address {
    background: #36d5f2;
    border-color: #36d5f2;
    .appCheckout__address__details__name,
    .appCheckout__address__details__description {
      color: white;
    }
  }
  .appProfileAdress__house {
    max-width: 50px;
    max-height: 50px;
    margin: 0;
    margin-right: 10px;
    margin-left: 20px;
  }

  .thankYouContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .isDefault {
    font-family: "Ember", sans-serif;
    font-size: 12px;
    background: #ff4f79;
    color: white;
    height: 20px;
    width: 60px;
    border-radius: 6px;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .notThisAddress {
    color: #36d5f2;
    margin: 15px 0 25px 0;
    font-weight: bold;
    cursor: pointer;
    text-align: center;
  }
  .notifyGuardian {
    display: flex;
    align-items: center;
    background: #442528;
    width: 100%;
    margin: 0 auto;
    color: white;
    padding: 10px;
    border-radius: 5px;
    font-size: 12px;
    img {
      margin-right: 10px;
    }
  }
</style>
