<template>
  <button
    :class="
      'appButton ' +
        color +
        ' appButton-' +
        color +
        (mini ? ' appButton-mini' : '')
    "
    @click="$emit('click')"
  >
    <div style="display: flex; align-items: center; justify-content: center;">
      <img v-if="isLoading" src="../assets/button-loader.svg" alt="Loading" style="margin-right: 10px;" />
      {{ text }}
      <slot />
    </div>
  </button>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "",
    },
    mini: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.appButton {
  width: 100%;
  box-sizing: border-box;
  padding: 15px;
  min-width: 360px;
  outline: none;
  border: none;
  background-color: #e5e5e5;
  color: white;
  cursor: pointer;
  font: {
    size: 18px;
  }

  &-mini {
    // height: 70px !important;
    min-width: auto !important;
  }

  &-red {
    background-color: #ff4f79;
    // box-shadow: 0px 4px 20px rgba(255, 79, 121, 0.5);
  }

  &-blue {
    background-color: #36d5f2;
    // box-shadow: 0px 4px 20px rgba(54, 213, 242, 0.5);
  }
}


</style>
