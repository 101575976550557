<template>
  <div class="appLoginError">
    <span>{{ message }}</span>
  </div>
</template>

<script>
export default {
    props: {
        message: {
            type: String,
            default: ''
        }
    }
}
</script>

<style lang="scss">
    .appLoginError {
        width: 100%;
        margin-top: 60px;
        display: grid;
        grid-template-columns: 1fr;

        span {
            display: block;
            
            font-size: 18px;
            color: #FF4F79;
            text-align: left;
            margin: auto;
        }
    }
</style>
